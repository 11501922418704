import React, { useEffect } from 'react'

const EnRedirect = () => {
  useEffect(() => {
    window.location.replace('/fr/home')
  }, []);
  return null;
};

export default EnRedirect;
